import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export function AlertDialog(props) {

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.cancelButtonHandle}
                fullWidth={props.fullWidth ? props.fullWidth : false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.dialogContentText}
                    </DialogContentText>
                    {props.dialogContent}
                </DialogContent>
                <DialogActions>
                    {props.cancelButton && <Button onClick={props.cancelButtonHandle} color="primary">
                        {props.cancelButton}
                    </Button>}

                    <Button onClick={props.okButtonHandle} color="primary">
                        {props.okButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
