import TextField from "@material-ui/core/TextField";
import {Controller} from "react-hook-form";
import React from "react";

export function DefaultInput(props) {

    return <Controller
        as={props.as ? props.as : TextField}
        rules={props.rules}
        format="DD-MM-Y"
        variant={props.variant ? props.variant : "outlined"}
        inputVariant="outlined"
        margin="normal"
        control={props.control}
        helperText={props.helperText}
        fullWidth
        error={props.error}
        id={props.id ? props.id : props.name}
        label={props.label}
        name={props.name}
        required={props.rules.required}
        defaultValue={props.value}
    />;
}
