import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {send} from "../Utils/Requests";
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";



const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
    nextStepButton: {
        right: '50px',
        position: 'absolute'
    },
    cursorPointer: {
        cursor: 'pointer'
    }
});

function Leftovers(props) {
    return
}

export function Deposits(props) {
    const [BalanceIndex, setBalanceIndex] = useState(0);
    const [Unit, setUnit] = useState(0);
    const [Balance, setBalance] = useState([]);
    const classes = useStyles();
    const MapUnit = {
        0: '₽ ',
        1: 'Л '
    };
    const MapFieldName = {
        0: 'money',
        1: 'liters'
    };
    const MapFuelFieldName = {
        0: 'show_money_value',
        1: 'show_liters_value'
    };
    const FindBalance = Balance.find((element, index, array) => {
        return element.id === props.user.selectedContract;
    }) ? Balance.find((element, index, array) => {
        return element.id === props.user.selectedContract;
    }) : {fuels: []};
    useEffect(function () {
        send('/api/v1/user/users/balance/').then((response) => {
            if(response.status === 200){
                response.json().then((result) => {
                    setBalance(result);
                });
            }
        })
    }, []);
    function updateBalance(event) {
        event.preventDefault();
        setBalance([]);
        send('/api/v1/user/users/balance/').then((response) => {
            if(response.status === 200){
                response.json().then((result) => {
                    setBalance(result);
                });
            }
        })
    }
    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>Баланс {props.user.contracts.find((element, index, array) => {
                return element.id === props.user.selectedContract;
            }) ? props.user.contracts.find((element, index, array) => {
                return element.id === props.user.selectedContract;
            }).name : 'Загрузка...'}</Typography>
            <Typography component="p" variant="h4">
                {FindBalance ? <div><div className={classes.cursorPointer} onClick={(e) => {
                    if (Unit) {
                        setUnit(0);
                        return
                    }
                    setUnit(1);
                }}>{MapUnit[Unit]} {FindBalance[MapFuelFieldName[Unit]] ? FindBalance[MapFieldName[Unit]] : 'Н/д'}</div>
                </div> : 'Загрузка...'}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                <p>Доступно</p>
                {FindBalance.fuels.map((fuel) => (<p key={fuel.id}>{fuel[MapFuelFieldName[Unit]] && MapUnit[Unit] + ' ' + fuel[MapFieldName[Unit]] + ' -'} {fuel.fuel_name}</p>))}
                на {new Date().toLocaleDateString()}
            </Typography>
            <div>
                {/*<Link color="primary" href="#" onClick={updateBalance}>*/}
                {/*    Обновить*/}
                {/*</Link>*/}
                <Tooltip title="Обновить">
                    <IconButton color="inherit">
                        <RefreshIcon onClick={updateBalance}/>
                    </IconButton>
                </Tooltip>

            </div>
        </>
    );
}
