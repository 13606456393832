import React, {useState} from "react";
import "../App.css"
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller } from "react-hook-form";
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { send } from '../Utils/Requests'
import { Copyright } from './General'
import { Link as LinkRouter } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random?gasstation)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export function Login(props) {
    const history = useHistory();
    const [ErrorLogin, setErrorLogin] = useState(false);
    const { handleSubmit, control, errors } = useForm({ mode: 'onChange' });
    const classes = useStyles();

    function validLogin (value){
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$|^(\+7([0-9]){10})$/i.test(value) ? true : "Неверно введен телефон или email"
    }

    const onSubmit = data => {
        props.loader(true);
        send('/api/v1/user/users/login/', data, 'post').then((response) => {
            if(response.status !== 200){
                props.loader(false);
                setErrorLogin(true);
                return null
            }
            return response.json()
        }).then((data) => {
            if(data === null){
                return
            }
            if (data.contracts.length !== 0){
                data.selectedContract = data.contracts[0].id;
            }
            props.userUpdate(data);
            history.push('/dashboard');
        });
    };
    if(props.user.id){
        history.push('/dashboard');
    }


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Войти
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Controller
                            as={TextField}
                            rules={{
                                validate: validLogin,
                                required: "Это поле обязательно"
                            }}
                            variant="outlined"
                            margin="normal"
                            control={control}
                            helperText={errors.login?.message}
                            required
                            fullWidth
                            error={!!errors.login}
                            id="login"
                            label="Телефон или Email"
                            name="login"
                            autoComplete="login"
                            autoFocus
                        />
                        <Controller
                            as={TextField}
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            variant="outlined"
                            margin="normal"
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            required
                            fullWidth
                            name="password"
                            label="Пароль"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Typography component="h5" variant="subtitle1" color="error">
                            {ErrorLogin ? "Неверный логин или пароль" : ""}
                        </Typography>


                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(onSubmit)}
                            className={classes.submit}
                        >
                            Войти
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/restore" component={LinkRouter} variant="body2">
                                    Забыли пароль?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/registration" variant="body2" component={LinkRouter}>
                                    {"Нет аккаунта? Регистрация"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}
