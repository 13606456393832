import {send} from "../Utils/Requests";
import {MyTable} from "../Helpers/Table";
import React, {useEffect, useRef, useState} from "react";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Controller, useForm} from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {DefaultInput} from "../Utils/Forms";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {AlertDialog} from "../Utils/Modals";
import {makeStyles} from "@material-ui/core/styles";
import {MTableToolbar} from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {AddBox} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {Alert} from "../Helpers/Alerts";
import Snackbar from "@material-ui/core/Snackbar";


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));


export function UserAuto(props) {
    const classes = useStyles();
    const tableRef = useRef();
    const [DeleteModalData, setDeleteModalData] = useState({open: false});
    const [Auto, setAuto] = useState({});
    const [SnackBarState, setSnackBarState] = useState({
        open: false,
        status: 'success',
        message: ''
    });
    const [ModalData, setModalData] = useState({
        open: false,
        title: 'Добавить'
    });
    const MapOrdering = {asc: '', desc: '-'};
    const {handleSubmit, control, errors} = useForm({mode: 'onChange'});
    useEffect(function () {
        tableRef.current && tableRef.current.onQueryChange();
    }, [props.SubUser, props.user]);
    function createAuto(e) {
        setAuto({});
        setModalData({...ModalData, open: true, title: 'Добавить'});
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({...SnackBarState, open: false});
    };
    async function okDialog(data) {
        const user_id_url = Auto.id ? Auto.id + '/' : '';
        const response = await send('/api/v1/user/cars/' + user_id_url, {user: props.SubUser.id, ...data}, Auto.id ? 'PATCH' : 'POST');
        if (response.status >= 400) {
            setSnackBarState({open: true, status: 'error', message: 'Ошибка сохранения'});
            return
        }
        setSnackBarState({open: true, status: 'success', message: 'Сохранено'});
        setModalData({...ModalData, open: false});
        tableRef.current && tableRef.current.onQueryChange();
    }
    function cancelDialog(e) {
        setModalData({...ModalData, open: false});
    }
    return (
        <>
            <Snackbar open={SnackBarState.open} autoHideDuration={6000}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={handleClose}>
                <Alert severity={SnackBarState.status} onClose={handleClose}>
                    {SnackBarState.message}
                </Alert>
            </Snackbar>
            <AlertDialog
                fullWidth={true}
                open={ModalData.open}
                okButtonHandle={handleSubmit(okDialog)}
                okButton="Сохранить"
                cancelButton="Отмена"
                cancelButtonHandle={cancelDialog}
                dialogContent={
                    <form className={classes.form} noValidate>
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors.brand?.message}
                            error={!!errors.brand}
                            label="Марка"
                            name="brand"
                            value={Auto.brand}
                        />
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors.model?.message}
                            error={!!errors.model}
                            label="Модель"
                            name="model"
                            value={Auto.model}
                        />
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors.number?.message}
                            error={!!errors.number}
                            label="Гос. номер"
                            name="number"
                            value={Auto.number}
                        />
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors.tank_volume?.message}
                            error={!!errors.tank_volume}
                            label="Объем бака"
                            name="tank_volume"
                            value={Auto.tank_volume}
                        />
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors.fuel_consumption?.message}
                            error={!!errors.fuel_consumption}
                            label="Расход топлива"
                            name="fuel_consumption"
                            value={Auto.fuel_consumption}
                        />
                        <FormControlLabel control={<Controller
                            as={<Switch checked={Auto.default} />}
                            control={control}
                            name="default"
                            defaultValue={Auto.default}
                        />} label="Авто по умолчанию" />
                    </form>
                }
                dialogTitle={ModalData.title}
            />
            <AlertDialog
                fullWidth={true}
                open={DeleteModalData.open}
                okButtonHandle={handleSubmit(async (data) => {
                    const response = await send('/api/v1/user/cars/' + Auto.id + '/', data, 'DELETE');
                    if(response.status >= 400){
                        setSnackBarState({open: true, status: 'error', message: 'Ошибка удаления'});
                        return
                    }
                    setSnackBarState({open: true, status: 'success', message: 'Удалено'});
                    setAuto({});
                    setDeleteModalData({...DeleteModalData, open: false});
                    tableRef.current && tableRef.current.onQueryChange();
                })}
                okButton="Удалить"
                cancelButton="Отмена"
                cancelButtonHandle={(e) => {setDeleteModalData({...DeleteModalData, open: false})}}
                dialogContentText="Все связанные с ним данные будут удалены"
                dialogTitle="Вы хотите удалить автомобиль?"
            />
            <MyTable
                tableRef={tableRef}
                title="Автомобили"
                // other props
                options={{
                    search: false
                }}
                data={query =>
                    new Promise(async (resolve, reject) => {
                        const response = await send('/api/v1/user/cars/?user=' + props.SubUser.id + '&offset=' + (query.pageSize * query.page) + '&limit=' + query.pageSize + '&ordering=' + (query.orderDirection ? MapOrdering[query.orderDirection] : '') + (query.orderBy ? query.orderBy.field : ''));
                        if (response.status !== 200) {
                            reject();
                        }

                        const data = await response.json();

                        let permitted = {};
                        for(let i in data.results){
                            permitted[data.results[i].id] = data.results[i].permitted;
                        }
                        // setPermittedState({...PermittedState, ...permitted});

                        resolve({
                            data: data.results,
                            page: query.page,
                            totalCount: data.count,
                        });
                    })
                }
                columns={[
                    {title: 'Марка', field: 'brand'},
                    {title: 'Модель', field: 'model'},
                    {title: 'Гос. номер', field: 'number'},
                    {title: 'Объем бака', field: 'tank_volume'},
                    {title: 'Расход топлива', field: 'fuel_consumption'},
                    {
                        title: 'Авто по умолчанию',
                        field: 'default',
                        render: rowData => {
                            if(rowData.default){
                                return 'Да';
                            }
                            return 'Нет';
                        }
                    }
                ]}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{padding: '0px 10px', 'text-align': 'right'}}>
                                <Tooltip title="Добавить">
                                    <IconButton color="inherit">
                                        <AddBox onClick={createAuto}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
                actions={[
                    {
                        icon: EditIcon,
                        tooltip: 'Редактировать',
                        onClick: (event, rowData) => {
                            setAuto(rowData)
                            console.log(rowData);
                            setModalData({...ModalData, open: true, title: 'Редактировать'})
                        }
                    },
                    {
                        icon: DeleteIcon,
                        tooltip: 'Удалить',
                        onClick: (event, rowData) => {
                            setAuto(rowData);
                            setDeleteModalData({...setDeleteModalData, open: true})
                        }
                    }
                ]}
            />
        </>
    )
}
