import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AppBar from "@material-ui/core/AppBar";
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import {send} from "../Utils/Requests";
import {useHistory} from "react-router-dom";
import {mainListItems} from "../Helpers/dashboardLeftListItems";
import {AccountCircle} from "@material-ui/icons";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from "@material-ui/core/Tooltip";
import CssBaseline from "@material-ui/core/CssBaseline";
import {AlertDialog} from "../Utils/Modals";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        'background-color': '#343a40',
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    badgeIcon: {
        color: 'green'
    }
}));

export function TopBar(props) {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);
    const [Dialog, setDialog] = useState({
        open: false,
        title: '',
        content: '',
        okButton: '',
        cancelButton: ''
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index, option) => {
        props.setUserState({...props.user, selectedContract: option.id});
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    let userIconTitle, userBadgeContent;
    useEffect(function () {
        if (!props.user.id) {
            return;
        }
        switch (props.user.entity.status) {
            case "created":
                setDialog({
                    open: true,
                    title: 'Заполните профиль',
                    content: 'Для того чтобы начать работу, необходимо заполнить данные о компании в профиле',
                    okButton: 'В профиль'
                });
                break;
            case "moderate":
                setDialog({
                    open: true,
                    title: 'Проверка',
                    content: 'Ваш аккаунт на проверке, скоро вы сможете им воспользоваться',
                    okButton: 'Ок'
                });
                break;
            default:
                break;

        }
    }, [props.user]);

    function okDialog() {
        if (props.user.entity.status === 'created') {
            setDialog({...Dialog, open: false});
            history.push('/profile');
        } else {
            setDialog({...Dialog, open: false});
        }
    }

    function cancelDialog(e) {

    }

    if (props.user.entity) {
        switch (props.user.entity.status) {
            case "created":
                userIconTitle = "Необходимо заполнить аккаунт";
                userBadgeContent = <ErrorOutlineIcon fontSize="small"/>;
                break;
            case "moderate":
                userIconTitle = "Идет проверка аккаунта";
                userBadgeContent = <ErrorOutlineIcon fontSize="small"/>;
                break;
            case "approved":
                userIconTitle = "Аккаунт подтвержен";
                userBadgeContent = <CheckCircleOutlineIcon fontSize="small"/>;
                break;
            default:
                userIconTitle = "";
                break;
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    function logout() {
        send('/api/v1/user/users/logout/', {}, 'post').then((response) => {
            if (response.status === 200) {
                props.updateUser();
                // history.push('/login');
            }
        })
    }

    if (!props.user.id) {
        return null;
    }

    return (
        <>
            <AlertDialog
                open={Dialog.open}
                okButtonHandle={okDialog}
                okButton={Dialog.okButton}
                cancelButtonHandle={cancelDialog}
                dialogContentText={Dialog.content}
                dialogTitle={Dialog.title}
            />
            <CssBaseline/><AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    Processing
                </Typography>
                {props.user?.limit?.balance && <IconButton color="inherit">
                    <Badge max={9999} badgeContent={props.user?.limit?.balance} color="secondary">
                        <LocalGasStationIcon/>
                    </Badge>
                </IconButton>}
                {/*<div className={classes.root}>*/}
                    <List component="nav" aria-label="Настройки договра">
                        <ListItem
                            button
                            aria-haspopup="true"
                            aria-controls="lock-menu"
                            aria-label="Выбор договора"
                            onClick={handleClickListItem}
                        >
                            {props.user.contracts.find((element, index, array) => {
                                return element.id === props.user.selectedContract;
                            }) && <ListItemText primary="Выбор договора"
                                                color="white"
                                                secondary={props.user.contracts.find((element, index, array) => {
                                                    return element.id === props.user.selectedContract;
                                                }).name}/>}

                        </ListItem>
                    </List>
                <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {props.user.contracts.map((option, index) => (
                            <MenuItem
                                key={option.id}
                                selected={option.id === props.user.selectedContract}
                                onClick={(event) => handleMenuItemClick(
                                    event,
                                    index,
                                    option
                                )}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Menu>
                {/*</div>*/}
                <Tooltip title={userIconTitle}>
                    <IconButton color="inherit">
                        <Badge badgeContent={userBadgeContent}>
                            <AccountCircle/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <IconButton color="inherit">
                    <ExitToApp onClick={logout}/>
                </IconButton>
            </Toolbar>
        </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List>{mainListItems}</List>
                <Divider/>
                {/*<List>{secondaryListItems}</List>*/}
            </Drawer>
        </>
    );
}
