import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Copyright } from "./General";
import { Link as LinkRouter } from "react-router-dom"
import { SingUpStage } from "./SingUpStages"
import { send } from "../Utils/Requests"
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export function SignUp(props) {
    const classes = useStyles();
    const history = useHistory();
    const [Stage, setStage] = useState(0);
    const [TimeCodeRetry, setTimeCodeRetry] = useState(0);
    const [RegistrationData, setRegistrationData] = useState({});

    function handleStage(data, setFormValue) {
        if (Stage === 0) {
            send(props.restore ? '/api/v1/user/users/restore_password/' : '/api/v1/user/users/registration/',
                {login: data.login}, 'post')
                .then((response) => {
                    if (response.status !== 200) {
                        response.json().then((result) => {
                            setRegistrationData({error: result.detail});
                        });
                    }
                    else{
                        response.json().then((result) => {
                            setRegistrationData({login: data.login, ...result});
                            setFormValue("login", "");
                            setTimeCodeRetry(result.second_before_new_send);
                            setStage(1);
                        });
                    }
                })
        }
        if (Stage === 1) {
            send(props.restore ? '/api/v1/user/users/restore_password_confirm/' : '/api/v1/user/users/confirm_account/', {
                login: data.login,
                code: data.code
            }, 'post')
                .then((response) => {
                    if (response.status !== 200) {
                        response.json().then((result) => {
                            setRegistrationData({error: result.detail});
                        });
                    }
                    else{
                        let regData = {...RegistrationData, ...data}
                        delete regData.error;
                        setRegistrationData(regData);
                        setFormValue("code", "");
                        setStage(2);
                    }
                })
        }
        if(Stage===2){
            send('/api/v1/user/users/confirm_account/', {login: data.login, code: data.code,
                password: data.password}, 'post')
                .then((response) => {
                    if(response.status !== 200){
                        response.json().then((result) => {
                            setRegistrationData({error: result.detail});
                        });
                    }
                    else{
                        history.push('/dashboard');
                    }
                })
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {props.restore ? 'Восстановление пароля' : 'Регистрация'}
                </Typography>
                <form className={classes.form} noValidate>
                    <SingUpStage registrationData={RegistrationData} stage={Stage} handleStage={handleStage}
                                 codeRetryTime={TimeCodeRetry}/>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to="/login" variant="body2" component={LinkRouter}>
                                Уже есть аккаунт? Войти
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}
