import React, {useEffect, useState} from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import {send} from "../Utils/Requests";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";


export function Chart(props) {
    const theme = useTheme();
    const [Data, setData] = useState([]);
    const [TypeChart, setTypeChart] = useState('moneys');
    const MapType = {
        moneys: 'Рубли',
        liters: 'Количество'
    };
    useEffect(() => {
        async function getChartData() {
            const response = await send('/api/v1/user/users/dashboard_chart/?type=' + TypeChart + '&contract=' + props.user.selectedContract);
            if (response.status !== 200) {
                return
            }
            setData(await response.json());
        }
        getChartData();
    }, [TypeChart, props.user]);

    return (
        <>
            <Grid container justify="space-between">
                <Grid item xs={10}>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                        Раходы в этом месяце
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <ButtonGroup disableElevation variant="text" color="primary" size="small">
                        <Button onClick={() => {
                            setTypeChart('moneys');
                        }}>Рубли</Button>
                        <Button onClick={() => {
                            setTypeChart('liters');
                        }}>Количество</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>


            {/*</Typography>*/}

            <ResponsiveContainer>
                <LineChart
                    data={Data}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
                    <YAxis stroke={theme.palette.text.secondary}>
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                        >
                            {MapType[TypeChart]}
                        </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
}
