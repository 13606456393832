import TextField from "@material-ui/core/TextField";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import { validLogin } from "../Utils/Validators";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import {send} from "../Utils/Requests";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cursorPointer: {
        cursor: 'pointer'
    }
}));

export function SingUpStage(props) {
    const classes = useStyles();
    const { handleSubmit, control, errors, setValue } = useForm({ mode: 'onChange' });
    const [SignUpData, setSignUpData] = useState({});
    const [Seconds, setSeconds] = useState(0);
    function getNewCode(e) {
        if(Seconds > 0){
            return
        }
        send('/api/v1/user/users/registration/', {login: SignUpData.login}, 'post')
            .then((response) => {
                if(response.status !== 200){
                    response.json().then((result) => {
                        setSignUpData({...SignUpData, ...{error: 'Ошибка получения кода'}})
                    });
                }
                else{
                    response.json().then((result) => {
                        // let sigData = SignUpData;
                        // delete sigData.error;
                        // setSignUpData(sigData);
                        setSeconds(result.second_before_new_send);
                    });
                }
            })
    }
    function handleStage(data) {
        const params = {...SignUpData, ...data};
        setSignUpData(params);
        props.handleStage(params, setValue);
    }
    useEffect(function () {
        setSeconds(props.codeRetryTime);
    }, [props.codeRetryTime]);
    useEffect(function () {
        if(!Seconds){
            return
        }
        setTimeout(function () {
            setSeconds(Seconds - 1);
        }, 1000)
    }, [Seconds]);
    switch (props.stage) {
        case 0:
            // setValue("login", "");
            return (<>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            as={TextField}
                            rules={{
                                validate: validLogin,
                                required: "Это поле обязательно"
                            }}
                            variant="outlined"
                            margin="normal"
                            control={control}
                            helperText={errors.login?.message}
                            required
                            fullWidth
                            error={!!errors.login}
                            id="login"
                            label="Телефон или Email"
                            name="login"
                            autoComplete="login"
                            autoFocus
                        />
                    </Grid>
            </Grid>
                <Typography component="h5" variant="subtitle1" color="error">
                    {props.registrationData.error ? props.registrationData.error : ""}
                </Typography>
                <Button
            type="button"
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleStage)}
            color="primary"
            className={classes.submit}>
                Получитиь код
            </Button></>);
        case 1:
            // setValue("code", "");
            return (<><Grid container spacing={2}><Grid item xs={12}><Controller
                as={TextField}
                rules={{
                    maxLength: 6,
                    minLength: 6,
                    required: "Это поле обязательно"
                }}
                variant="outlined"
                margin="normal"
                control={control}
                helperText={errors.code?.message}
                required
                fullWidth
                error={!!errors.code}
                defaultValue=""
                id="code"
                label="Введите код из сообщения"
                name="code"
                autoComplete="code"
                autoFocus
            /></Grid></Grid>
                <Typography component="h5" variant="subtitle1" color="error">
                    {props.registrationData.error ? props.registrationData.error : ""}
                </Typography>
                <Link className={classes.cursorPointer} variant="body2" onClick={getNewCode}>
                    {Seconds ? 'Получить новый код через ' + Seconds : 'Получить новый код'}
                </Link>
                <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={handleSubmit(handleStage)}
                color="primary"
                className={classes.submit}>
                Продолжить
            </Button></>);
        case 2:
            // setValue("password", "");
            return (<><Grid container spacing={2}><Grid item xs={12}><Controller
                as={TextField}
                rules={{
                    required: "Это поле обязательно"
                }}
                variant="outlined"
                margin="normal"
                control={control}
                helperText={errors.password?.message}
                type="password"
                required
                fullWidth
                defaultValue=""
                error={!!errors.password}
                id="password"
                label="Введите пароль"
                name="password"
                autoComplete="password"
                autoFocus
            /></Grid></Grid>
                <Typography component="h5" variant="subtitle1" color="error">
                    {props.registrationData.error ? props.registrationData.error : ""}
                </Typography><Button
                type="button"
                fullWidth
                variant="contained"
                onClick={handleSubmit(handleStage)}
                color="primary"
                className={classes.submit}>
                Сохранить пароль
            </Button></>);
        default:
            break
    }
}
