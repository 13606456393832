import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useForm} from "react-hook-form";
import {DefaultInput} from "../Utils/Forms";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import "moment/locale/ru";
import Button from "@material-ui/core/Button";
import {send} from "../Utils/Requests";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {MyTable} from "../Helpers/Table";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export function Profile(props) {
    const classes = useStyles();
    const [SnackBarState, setSnackBarState] = useState({
        open: false,
        status: 'success',
        message: ''
    });
    const {handleSubmit, control, errors} = useForm({mode: 'onChange'});
    const tableRef = useRef();
    const entity = props.user.entity;

    let CheckedStations = null;

    async function onSave(data) {
        if (CheckedStations !== null) {
            data.stations = CheckedStations;
        }

        const response = await send('/api/v1/user/users/change_entity/', {
            ...data,
            tax_regime_date: typeof (data.tax_regime_date) === 'string' ? data.tax_regime_date : data.tax_regime_date.format('YYYY-MM-DD')
        }, 'post');
        const result = await response.json();
        if (response.status !== 200) {
            setSnackBarState({
                open: true,
                message: result.non_field_errors ? result.non_field_errors[0] : 'Ошибка сохранения',
                status: 'error'});
        } else {
            setSnackBarState({open: true, message: 'Сохранено', status: 'success'});
            props.setUser({...props.user, entity: result});
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({...SnackBarState, open: false});
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <div>
                    <Snackbar open={SnackBarState.open} autoHideDuration={6000}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={handleClose}>
                        <Alert severity={SnackBarState.status} onClose={handleClose}>
                            {SnackBarState.message}
                        </Alert>
                    </Snackbar>
                    {/*<Snackbar*/}
                    {/*    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}*/}
                    {/*    open={SnackBarState.open}*/}
                    {/*    // onClose={handleClose}*/}
                    {/*    message={SnackBarState.message}*/}
                    {/*    // key={vertical + horizontal}*/}
                    {/*/>*/}
                </div>
                <Container maxWidth="lg" className={classes.container}>
                    {/*<Grid container spacing={3}>*/}
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>Профиль</Typography>
                            <form className={classes.form} noValidate>

                                <Grid
                                    container
                                    spacing={3}
                                >
                                    {/*<TextField id="standard-basic" label="Standard" value={entity.name} />*/}
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.name?.message}
                                            error={!!errors.name}
                                            label="Название компании"
                                            name="name"
                                            value={entity.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.name_another_lang?.message}
                                            error={!!errors.name_another_lang}
                                            label="Название компании на иностранном языке"
                                            name="name_another_lang"
                                            value={entity.name_another_lang}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.entity_address?.message}
                                            error={!!errors.entity_address}
                                            label="Юридический адрес"
                                            name="entity_address"
                                            value={entity.entity_address}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.post_address?.message}
                                            error={!!errors.post_address}
                                            label="Почтовый адрес"
                                            name="post_address"
                                            value={entity.post_address}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.phone?.message}
                                            error={!!errors.phone}
                                            label="Телефон"
                                            name="phone"
                                            value={entity.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.site?.message}
                                            error={!!errors.site}
                                            label="Сайт"
                                            name="site"
                                            value={entity.site}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.director_name?.message}
                                            error={!!errors.director_name}
                                            label="Руководитель ФИО"
                                            name="director_name"
                                            value={entity.director_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.accountant_name?.message}
                                            error={!!errors.accountant_name}
                                            label="Бухгалтер ФИО"
                                            name="accountant_name"
                                            value={entity.accountant_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.contact_man?.message}
                                            error={!!errors.contact_man}
                                            label="Контактное лицо"
                                            name="contact_man"
                                            value={entity.contact_man}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.contact_man_phone?.message}
                                            error={!!errors.contact_man_phone}
                                            label="Контактное лицо телефон"
                                            name="contact_man_phone"
                                            value={entity.contact_man_phone}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно",
                                                pattern: {
                                                    value: /[0-9]{10}/,
                                                    message: "Только цифры 10 - 12"
                                                }
                                            }}
                                            control={control}
                                            helperText={errors.inn?.message}
                                            error={!!errors.inn}
                                            label="ИНН"
                                            name="inn"
                                            value={entity.inn}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно",
                                                pattern: {
                                                    value: /[0-9]{14}/,
                                                    message: "Только цифры"
                                                }
                                            }}
                                            control={control}
                                            helperText={errors.kpp?.message}
                                            error={!!errors.kpp}
                                            label="КПП"
                                            name="kpp"
                                            value={entity.kpp}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.ogrn?.message}
                                            error={!!errors.ogrn}
                                            label="ОГРН"
                                            name="ogrn"
                                            value={entity.ogrn}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.okpo?.message}
                                            error={!!errors.okpo}
                                            label="ОКПО"
                                            name="okpo"
                                            value={entity.okpo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.okato?.message}
                                            error={!!errors.okato}
                                            label="ОКАТО"
                                            name="okato"
                                            value={entity.okato}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.okved?.message}
                                            error={!!errors.okved}
                                            label="ОКВЭД"
                                            name="okved"
                                            value={entity.okved}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.checking_account?.message}
                                            error={!!errors.checking_account}
                                            label="Расчетный счет"
                                            name="checking_account"
                                            value={entity.checking_account}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.bank_name?.message}
                                            error={!!errors.bank_name}
                                            label="Наименование банка"
                                            name="bank_name"
                                            value={entity.bank_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.correspondent_account?.message}
                                            error={!!errors.correspondent_account}
                                            label="Корресп. счет"
                                            name="correspondent_account"
                                            value={entity.correspondent_account}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.bik?.message}
                                            error={!!errors.bik}
                                            label="БИК"
                                            name="bik"
                                            value={entity.bik}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.tax_regime_no?.message}
                                            error={!!errors.tax_regime_no}
                                            label="Реж. налогообл. №"
                                            name="tax_regime_no"
                                            value={entity.tax_regime_no}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={"ru"}>
                                            <DefaultInput
                                                as={DatePicker}
                                                rules={{
                                                    required: "Это поле обязательно"
                                                }}
                                                control={control}
                                                helperText={errors.tax_regime_date?.message}
                                                error={!!errors.tax_regime_date}
                                                label="Дата реж. налогообл."
                                                name="tax_regime_date"
                                                variant="inline"
                                                value={entity.tax_regime_date}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.affiliation_details?.message}
                                            error={!!errors.affiliation_details}
                                            label="Свединия об аффилированности"
                                            name="affiliation_details"
                                            value={entity.affiliation_details}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DefaultInput
                                            rules={{
                                                required: "Это поле обязательно"
                                            }}
                                            control={control}
                                            helperText={errors.average_number_of_employees?.message}
                                            error={!!errors.average_number_of_employees}
                                            label="Среднесписочная численность сотрудников за предыдущий год/ в текущем году"
                                            name="average_number_of_employees"
                                            value={entity.average_number_of_employees}
                                        />
                                    </Grid>
                                </Grid>

                            </form>
                            <Grid
                                container
                                spacing={3}
                                className={classes.container}
                            >
                                <Grid item xs={12}>
                                    <MyTable
                                        tableRef={tableRef}
                                        title="Доступные АЗС"
                                        localization={{
                                            toolbar: {
                                                nRowsSelected: '{0} АЗС выбрано'
                                            }
                                        }}
                                        options={{
                                            search: false,
                                            selection: true,
                                            paging: false
                                        }}
                                        data={query =>
                                            new Promise(async (resolve, reject) => {
                                                const response = await send('/api/v1/user/stations/profile_stations/');
                                                if (response.status !== 200) {
                                                    reject();
                                                }
                                                const data = await response.json();
                                                resolve({
                                                    data: data
                                                });
                                            })
                                        }
                                        columns={[
                                            {title: 'Сеть', field: 'company_name'},
                                            {title: 'Название', field: 'name'},
                                            {title: 'Адрес', field: 'address'}
                                        ]}
                                        onSelectionChange={(rows) => {
                                            CheckedStations = rows.map(row => (row.id))
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit(onSave)}
                                className={classes.submit}
                            >
                                Сохранить
                            </Button>
                        </Paper>
                    </Grid>
                    <Box pt={4}>
                        {/*<Copyright />*/}
                    </Box>
                </Container>
            </main>
        </>
    );
}
