import Container from "@material-ui/core/Container";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Controller, useForm} from "react-hook-form";
import {DefaultInput} from "../Utils/Forms";
import "moment/locale/ru";
import {send} from "../Utils/Requests";
import Snackbar from "@material-ui/core/Snackbar";
import {MyTable} from "../Helpers/Table";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Alert} from "../Helpers/Alerts";
import Grid from "@material-ui/core/Grid";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    download: {
        margin: '0 10px'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

export function Reports(props) {
    const classes = useStyles();
    const [SnackBarState, setSnackBarState] = useState({
        open: false,
        status: 'success',
        message: ''
    });

    const MapOrdering = {asc: '', desc: '-'};

    const tableRef = useRef();
    const {handleSubmit, control, errors} = useForm({mode: 'onChange'});

    const timerIdMap = useRef({});
    const reportLoadId = useRef({});

    useEffect(() => {return () => {
        for(let timer in timerIdMap.current){
            clearInterval(timerIdMap.current[timer]);
        }
    }}, []);

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [props.user]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({...SnackBarState, open: false});
    };

    async function getReport(data) {
        const response = await send('/api/v1/user/reports/', {...data, contract: props.user.selectedContract}, 'POST')
        if (response.status !== 201) {
            setSnackBarState({...SnackBarState, open: true, message: 'Ошибка', status: 'error'});
            return
        }
        const response_data = await response.json();
        setSnackBarState({
            ...SnackBarState,
            open: true,
            message: 'Отчет сформируется через несколько секунд',
            status: 'success'
        });
        reportLoadId.current[response_data.id] = 1;
        tableRef.current && tableRef.current.onQueryChange();
    }


    return (
        <>
            <main className={classes.content}>
                <Snackbar open={SnackBarState.open} autoHideDuration={6000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={handleClose}>
                    <Alert severity={SnackBarState.status} onClose={handleClose}>
                        {SnackBarState.message}
                    </Alert>
                </Snackbar>

                <div className={classes.appBarSpacer}/>
                <div>

                </div>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>Отчеты</Typography>
                            <form className={classes.form} noValidate>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" className={classes.form}
                                                     error={errors.type_report}>
                                            <InputLabel id="label_type_report">Тип отчета</InputLabel>
                                            <Controller
                                                as={<Select
                                                    labelId="label_type_report"
                                                    id="type_report"
                                                    labelWidth="85"
                                                >
                                                    <MenuItem value="0">Отчет по водителям</MenuItem>
                                                    {/*<MenuItem value="1">Отчет по видам топлива</MenuItem>*/}
                                                    <MenuItem value="2">Акт сверки</MenuItem>
                                                    <MenuItem value="3">Отчет по покупкам водителей</MenuItem>
                                                    {/*<MenuItem value="l">Литры</MenuItem>*/}
                                                </Select>}
                                                rules={{
                                                    required: "Это поле обязательно"
                                                }}
                                                control={control}
                                                name="type_report"
                                                defaultValue="0"
                                                // onChange={handleLimitUnit}
                                            />
                                            <FormHelperText>
                                                {errors?.limit_unit && errors.limit_unit.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={"ru"}>
                                            <DefaultInput
                                                as={DatePicker}
                                                rules={{
                                                    required: "Это поле обязательно"
                                                }}
                                                control={control}
                                                helperText={errors.date_start?.message}
                                                error={!!errors.date_start}
                                                label="С"
                                                name="date_start"
                                                variant="inline"
                                                value={new Date().toISOString()}
                                                // value=""
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils} locale={"ru"}>
                                            <DefaultInput
                                                as={DatePicker}
                                                rules={{
                                                    required: "Это поле обязательно"
                                                }}
                                                control={control}
                                                helperText={errors.date_end?.message}
                                                error={!!errors.date_end}
                                                label="По"
                                                value={new Date().toISOString()}
                                                name="date_end"
                                                variant="inline"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </form>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit(async (data) => {await getReport(data)})}
                                className={classes.submit}
                            >
                                Сформировать
                            </Button>
                            <Grid
                                container
                                spacing={3}
                                className={classes.container}
                            >
                                <Grid item xs={12}>
                                    <MyTable
                                        tableRef={tableRef}
                                        title="История"
                                        options={{
                                            search: false
                                        }}
                                        data={query =>
                                            new Promise(async (resolve, reject) => {
                                                console.log(query)
                                                const response = await send('/api/v1/user/reports/?offset=' + (query.pageSize * query.page) + '&limit=' + query.pageSize + '&contract=' + props.user.selectedContract + '&ordering=' + (query.orderDirection ? MapOrdering[query.orderDirection] : '') + (query.orderBy ? query.orderBy.field : ''));
                                                if (response.status !== 200) {
                                                    reject();
                                                }
                                                const data = await response.json();
                                                if (Object.keys(reportLoadId.current).length !== 0) {
                                                    for (let i in data.results) {
                                                        if (reportLoadId.current[data.results[i].id]) {
                                                            if (data.results[i].status !== 'created') {
                                                                setSnackBarState({
                                                                    ...SnackBarState,
                                                                    open: true,
                                                                    message: 'Отчет сформирован',
                                                                    status: 'success'
                                                                });
                                                                delete reportLoadId.current[data.results[i].id];
                                                            } else {
                                                                if(!timerIdMap.current[data.results[i].id]){
                                                                    timerIdMap.current[data.results[i].id] = setInterval(async function () {
                                                                        const rowId = data.results[i].id;
                                                                        const response = await send('/api/v1/user/reports/' + rowId + '/');
                                                                        const result = await response.json();
                                                                        if(result.status !== 'created'){
                                                                            setSnackBarState({
                                                                                ...SnackBarState,
                                                                                open: true,
                                                                                message: 'Отчет сформирован',
                                                                                status: 'success'
                                                                            });
                                                                            clearInterval(timerIdMap.current[rowId]);
                                                                            delete timerIdMap.current[rowId];
                                                                            delete reportLoadId.current[rowId];
                                                                            tableRef.current && tableRef.current.onQueryChange();
                                                                        }

                                                                    }, 3000);
                                                                }
                                                            }
                                                            break;
                                                        }
                                                    }
                                                }
                                                resolve({
                                                    data: data.results,
                                                    page: query.page,
                                                    totalCount: data.count,
                                                });
                                            })
                                        }
                                        columns={[
                                            {
                                                title: 'Скачать',
                                                field: 'files',
                                                sorting: false,
                                                render: rowData => rowData.files.map((file) => <a key={file.id} className={classes.download} href={file.file_path} target={"_blank"}>{file.format}</a>)
                                            },
                                            {title: 'Статус', field: 'display_status'},
                                            {title: 'Отчет', field: 'display_type_report'},
                                            {
                                                title: 'Дата начала',
                                                field: 'date_start',
                                                render: rowData => {return new Date(rowData.date_start).toLocaleString()}
                                                },
                                            {
                                                title: 'Дата конца',
                                                field: 'date_end',
                                                render: rowData => {return new Date(rowData.date_end).toLocaleString()}
                                                },
                                            {
                                                title: 'Дата формирования',
                                                field: 'date_created',
                                                render: rowData => {return new Date(rowData.date_created).toLocaleString()}
                                            }
                                        ]}

                                        actions={[
                                            // {
                                            //     icon: GetAppIcon,
                                            //     tooltip: 'Скачать',
                                            //     onClick: (event, rowData) => {
                                            //
                                            //     }
                                            // }
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Container>
            </main>
        </>
    );
}
