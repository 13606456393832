
import React, {useEffect, useRef, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {send} from "../Utils/Requests";
import Typography from "@material-ui/core/Typography";
import {MyTable} from "../Helpers/Table";


const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export function Orders(props) {
    const classes = useStyles();
    const [Orders, setOrders] = useState({});
    const tableRef = useRef();
    const MapOrdering = {asc: '', desc: '-'};
    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [props.user]);
    return (
        <>
            <MyTable
                tableRef={tableRef}
                title="Покупки"
                options={{
                    search: false
                }}
                data={query =>
                    new Promise(async (resolve, reject) => {
                        const response = await send('/api/v1/user/orders/?offset=' + (query.pageSize * query.page) + '&limit=' + query.pageSize + '&contract=' + props.user.selectedContract + '&ordering=' + (query.orderDirection ? MapOrdering[query.orderDirection] : '') + (query.orderBy ? query.orderBy.field : ''));
                        if (response.status !== 200) {
                            reject();
                        }
                        const data = await response.json();
                        resolve({
                            data: data.results,
                            page: query.page,
                            totalCount: data.count,
                        });
                    })
                }
                columns={[
                    {
                        title: 'Дата',
                        field: 'date_created',
                        render: rowData => {return new Date(rowData.date_created).toLocaleString()}
                    },
                    {
                        title: 'ФИО',
                        sorting: false,
                        field: 'user_name'
                    },
                    {
                        title: 'Цена',
                        field: 'final_price_fuel'
                    },
                    {
                        title: 'Количество',
                        field: 'litre_completed'
                    },
                    {
                        title: 'Сумма',
                        field: 'final_sum_paid_completed'
                    }
                ]}

                actions={[
                    // {
                    //     icon: GetAppIcon,
                    //     tooltip: 'Скачать',
                    //     onClick: (event, rowData) => {
                    //
                    //     }
                    // }
                ]}
            />
        </>
    );
}
