import {send} from "../Utils/Requests";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {MyTable} from "../Helpers/Table";
import React, {useEffect, useRef, useState} from "react";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Controller, useForm} from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {DefaultInput} from "../Utils/Forms";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {AlertDialog} from "../Utils/Modals";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));


export function LimitByFuels(props) {
    const classes = useStyles();
    const tableRef = useRef();
    const [PermittedState, setPermittedState] = useState({});
    const [LimitModalData, setLimitModalData] = useState({open: false, title: 'Лимит'});
    const [Fuel, setFuel] = useState(0);
    const {handleSubmit, control, errors} = useForm({mode: 'onChange'});
    const LimitTimeNameMap = {
        days: 'дней',
        months: 'месяцев'
    };
    let isServicesContract = () => props.user.contracts.find(({id}) => id === props.user.selectedContract).contract_type_entity === 2;
    useEffect(function () {
        tableRef.current && tableRef.current.onQueryChange();
    }, [props.SubUser, props.user]);
    async function okDialogLimit(data) {
        const response = await send('/api/v1/user/users/'+ props.SubUser.id +'/set_limit/', {
            ...data,
            user_id: props.SubUser.id,
            contract: props.user.selectedContract,
            fuel: Fuel
        }, 'post');
        if(response.status !== 200){
            props.setSnackBarState({open: true, status: 'error', message: 'Ошибка сохранения'});
            return
        }
        setLimitModalData({...LimitModalData, open: false});
        props.setSnackBarState({open: true, status: 'success', message: 'Сохранено'});
        tableRef.current && tableRef.current.onQueryChange();
    }
    function cancelLimitDialog(e) {
        setLimitModalData({...LimitModalData, open: false});
    }
    function handleLimitUnit(e) {
        props.setSubUserLimit({...props.SubUserLimit, limit_unit: e[0].target.value});
        return e[0].target.value;
    }
    function handleLimitTime(e) {
        props.setSubUserLimit({...props.SubUserLimit, time_limit: e[0].target.value});
        return e[0].target.value;
    }
    // const CurrentFuels = props.user.
    return (
        <>
            <AlertDialog
                fullWidth={true}
                open={LimitModalData.open}
                okButtonHandle={handleSubmit(async (data) => await okDialogLimit(data, tableRef.current))}
                okButton="Сохранить"
                cancelButton="Отмена"
                cancelButtonHandle={cancelLimitDialog}
                dialogTitle={LimitModalData.title}
                dialogContent={
                    <form className={classes.form} noValidate>
                        <FormControl variant="outlined" className={classes.form} error={errors.limit_unit}>
                            <InputLabel id="label_limit_unit">Лимит по</InputLabel>
                            <Controller
                                as={<Select
                                    labelId="label_limit_unit"
                                    id="limit_unit"
                                    labelWidth="72"
                                >
                                    <MenuItem value="m">Деньги</MenuItem>
                                    {!isServicesContract() && <MenuItem value="l">Литры</MenuItem>}
                                </Select>}
                                rules={{
                                    required: "Это поле обязательно"
                                }}
                                control={control}
                                name="limit_unit"
                                defaultValue={props.SubUserLimit?.limit_unit}
                                onChange={handleLimitUnit}
                            />
                            <FormHelperText>
                                {errors?.limit_unit && errors.limit_unit.message}
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.form} error={errors.time_limit}>
                            <InputLabel id="label_time_limit">Срок лимита по</InputLabel>
                            <Controller
                                as={<Select
                                    labelId="label_time_limit"
                                    id="time_limit"
                                    labelWidth="120"
                                >
                                    <MenuItem value="days">Дни</MenuItem>
                                    <MenuItem value="months">Месяцы</MenuItem>
                                </Select>}
                                rules={{
                                    required: "Это поле обязательно"
                                }}
                                control={control}
                                name="time_limit"
                                onChange={handleLimitTime}
                                defaultValue={props.SubUserLimit?.time_limit ? props.SubUserLimit?.time_limit : "days"}
                            />
                            <FormHelperText>
                                {errors?.time_limit && errors.time_limit.message}
                            </FormHelperText>
                        </FormControl>
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors?.time_limit_action?.message}
                            error={!!errors?.time_limit_action}
                            label={"Срок действия лимита " + (props.SubUserLimit?.time_limit ? LimitTimeNameMap[props.SubUserLimit.time_limit] : LimitTimeNameMap.days)}
                            name="time_limit_action"
                            value={props.SubUserLimit?.time_limit_action}
                        />
                        <DefaultInput
                            rules={{
                                required: "Это поле обязательно"
                            }}
                            control={control}
                            helperText={errors?.limit_value?.message}
                            error={!!errors?.limit_value}
                            label={isServicesContract() || props.SubUserLimit?.limit_unit === 'm' ? 'Лимит рублей' : 'Лимит литров'}
                            name="limit_value"
                            value={props.SubUserLimit?.limit_value}
                        />
                        <FormGroup aria-label="position" row>
                            <FormControlLabel control={<Controller
                                as={<Switch checked={props.SubUserLimit?.transfer_balance} />}
                                control={control}
                                name="transfer_balance"
                                defaultValue={props.SubUserLimit?.transfer_balance}
                            />} label="Перенос остатка" />
                            <FormControlLabel control={<Controller
                                as={<Switch checked={props.SubUserLimit?.transfer_balance} />}
                                control={control}
                                name="active"
                                defaultValue={props.SubUserLimit?.active}
                            />} label="Использовать лимит" />
                        </FormGroup>
                    </form>
                }
            />
            <MyTable
                tableRef={tableRef}
                title="Лимиты"
                // other props
                options={{
                    paging: false,
                    search: false
                }}
                data={query =>
                    new Promise(async (resolve, reject) => {
                        if (props.SubUser.id){
                            const response = await send('/api/v1/user/users/' + props.SubUser.id + '/user_limits/?contract=' + props.user.selectedContract);
                            if (response.status !== 200) {
                                reject();
                            }
                            const data = await response.json();
                            let permitted = {};
                            for(let i in data){
                                permitted[data[i].id] = data[i].permitted;
                            }
                            setPermittedState({...PermittedState, ...permitted});
                            resolve({
                                data: data
                            });
                        }
                        else {
                            resolve({
                                data: []
                            });
                        }
                    })
                }
                columns={[
                    {
                        title: 'Тип',
                        field: 'name'
                    },
                    {
                        title: 'Лимит',
                        field: 'limits',
                        render: rowData => {
                            if(rowData.limit){
                                return rowData.limit.display_limit_unit;
                            }
                            return '';
                        }
                    },
                    {
                        title: 'Остаток',
                        field: 'balance',
                        render: rowData => {
                            if(rowData.limit){
                                return rowData.limit.balance;
                            }
                            return '';
                        }
                    },
                    {
                        title: 'Использовать лимит',
                        field: 'limits',
                        render: rowData => {
                            if(rowData.limit){
                                return rowData.limit.display_active;
                            }
                            return '';
                        }
                    },
                    {
                        title: 'Разрешить покупку',
                        field: 'permitted',
                        hidden: isServicesContract(),
                        render: rowData => (<Switch
                            checked={PermittedState[rowData.id]}
                            onChange={async (e) => {
                                const response = await send(
                                    '/api/v1/user/users/' + props.SubUser.id + '/change_permitted_fuel/',
                                    {
                                        contract: props.user.selectedContract,
                                        fuel: rowData.id,
                                        permitted: e.target.checked
                                    },
                                    'POST'
                                );
                                const response_data = await response.json();
                                if (response.status !== 200) {
                                    setPermittedState({...PermittedState});
                                }
                                else {
                                    setPermittedState({...PermittedState, [rowData.id]: response_data.permitted});
                                }

                            }}
                        />)
                    }
                ]}
                // components={{
                //     Toolbar: props => (
                //         <div>
                //             <MTableToolbar {...props} />
                //             <div style={{padding: '0px 10px', 'text-align': 'right'}}>
                //                 <Tooltip title="Добавить">
                //                     <IconButton color="inherit">
                //                         <AddBox onClick={createDriver}/>
                //                     </IconButton>
                //                 </Tooltip>
                //             </div>
                //         </div>
                //     ),
                // }}
                actions={[
                    {
                        icon: AccountBalanceWalletIcon,
                        tooltip: 'Лимит',
                        onClick: (event, rowData) => {
                            setFuel(rowData.id);
                            if(!rowData.limit){
                                props.setSubUserLimit(props.UserLimitInitState)
                            }
                            else{
                                props.setSubUserLimit({...props.SubUserLimit, ...rowData.limit});
                            }
                            setLimitModalData({...LimitModalData, open: true, title: 'Редактировать'})
                        }
                    }
                ]}
            />
        </>
    )
}
