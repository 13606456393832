import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
// import logo from './logo.svg';
import {Login} from './Components/Authorization'
import {Dashboard} from './Components/Dashboard';
import {SignUp} from './Components/SignUp';
import LoadingOverlay from 'react-loading-overlay';
import {send} from './Utils/Requests'
import './App.css';
import {Profile} from "./Components/Profile";
import {TopBar} from "./Components/TopBar";
import {makeStyles} from "@material-ui/core/styles";
import {Drivers} from "./Components/Drivers";
import {Reports} from "./Components/Reports";
import Payments from "./Components/Payments";
import {MapComponent} from "./Components/Map";


export const UserContext = React.createContext({});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    }
}));

function PrivateRoute({children, ...rest}) {
    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <UserContext.Consumer>
            {user => (
                // eslint-disable-next-line react/react-in-jsx-scope
                <Route
                    {...rest}
                    render={({location}) =>
                        user.id ? (
                            children
                        ) : (
                            // eslint-disable-next-line react/react-in-jsx-scope
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {from: location}
                                }}
                            />
                        )
                    }
                />
            )
            }

        </UserContext.Consumer>
    );
}

function App() {
    const [User, setUser] = useState(null);
    const [Loader, setLoader] = useState(true);
    const classes = useStyles();
    async function getUser() {
        const response = await send('/api/v1/user/users/user_info/', {}, 'post');
        setTimeout(function () {
            setLoader(false);
        }, 1000);
        if (response.status !== 200) {
            setUser({});
        } else {
            if(User !== null && User.id){
                setUser({...User, ...await response.json()});
                return
            }
            const result = await response.json();
            if (result.contracts.length !== 0){
                result.selectedContract = result.contracts[0].id;
            }
            setUser(result);
        }
    }
    useEffect(() => {
        getUser();
    }, []);

    function ScreenLoader(stateLoader) {
        setLoader(stateLoader);
    }

    function userUpdate(user) {
        setUser(user);
    }

    if (User === null) {
        return <LoadingOverlay
            active={Loader}
            spinner
            text='Пожалуйста подождите...'
        >
            <div className="App-loading"/>
        </LoadingOverlay>;
    }
    return (
        <UserContext.Provider value={User}>
            <Router>
                <LoadingOverlay
                    active={Loader}
                    spinner
                    text='Пожалуйста подождите...'
                >
                    <div className={classes.root}>
                        <TopBar user={User} updateUser={getUser} setUserState={setUser}/>
                        <Switch>
                            <Route path="/login">
                                <Login loader={ScreenLoader} userUpdate={userUpdate} user={User}/>
                            </Route>
                            <Route path="/registration">
                                <SignUp restore={false} loader={ScreenLoader} userUpdate={userUpdate}/>
                            </Route>
                            <Route path="/restore">
                                <SignUp restore={true} loader={ScreenLoader} userUpdate={userUpdate}/>
                            </Route>
                            <PrivateRoute path="/dashboard">
                                <Dashboard loader={ScreenLoader} user={User}/>
                            </PrivateRoute>
                            <PrivateRoute path="/profile">
                                <Profile loader={ScreenLoader} user={User} setUser={setUser}/>
                            </PrivateRoute>
                            <PrivateRoute path="/drivers">
                                <Drivers user={User}/>
                            </PrivateRoute>
                            <PrivateRoute path="/reports">
                                <Reports user={User}/>
                            </PrivateRoute>
                            <PrivateRoute path="/payments">>
                                <Payments user={User}/>
                            </PrivateRoute>
                            <PrivateRoute path="/map">>
                                <MapComponent updateUser={getUser} user={User}/>
                            </PrivateRoute>
                            <PrivateRoute path="/">
                                <Redirect to="/dashboard"/>
                            </PrivateRoute>
                        </Switch>
                    </div>
                </LoadingOverlay>
            </Router>
        </UserContext.Provider>
    );
}

export default App;
