import Cookies from 'js-cookie';

export async function send(url, data, type='get', contentType='application/json') {
    let body = data;
    if(contentType === 'application/json' && (type.toLocaleLowerCase() === 'post' || type.toLocaleLowerCase() === 'patch')){
        body = JSON.stringify(data);
    }
    return await fetch(url, {
        method: type,
        headers: {
            'Content-Type': contentType,
            'X-CSRFToken': Cookies.get('csrftoken')
        },
        body: body
    })
}
