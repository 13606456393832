import Container from "@material-ui/core/Container";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useForm} from "react-hook-form";
import {DefaultInput} from "../Utils/Forms";
import "moment/locale/ru";
import {send} from "../Utils/Requests";
import Snackbar from "@material-ui/core/Snackbar";
import {MyTable} from "../Helpers/Table";
import {AlertDialog} from "../Utils/Modals";
import {Alert} from "../Helpers/Alerts";
import {MTableToolbar} from "material-table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {AddBox} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    download: {
        margin: '0 10px'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

export default function Payments(props) {
    const classes = useStyles();
    const MapOrdering = {asc: '', desc: '-'};
    const [ModalData, setModalData] = useState({open: false, title: 'Добавить'});
    const [SnackBarState, setSnackBarState] = useState({
        open: false,
        status: 'success',
        message: ''
    });

    const tableRef = useRef();
    const {handleSubmit, control, errors} = useForm({mode: 'onChange'});

    const timerIdMap = useRef({});
    const reportLoadId = useRef({});

    useEffect(() => {
        return () => {
            for (let timer in timerIdMap.current) {
                clearInterval(timerIdMap.current[timer]);
            }
        }
    }, []);

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [props.user]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({...SnackBarState, open: false});
    };

    async function getBill(data) {
        const response = await send('/api/v1/user/bills/', {...data, contract: props.user.selectedContract}, 'POST');
        if (response.status !== 201) {
            setSnackBarState({...SnackBarState, open: true, message: 'Ошибка', status: 'error'});
            return
        }
        const response_data = await response.json();
        setSnackBarState({
            ...SnackBarState,
            open: true,
            message: 'Счет сформируется через несколько секунд',
            status: 'success'
        });
        setModalData({...ModalData, open: false});
        reportLoadId.current[response_data.id] = 1;
        tableRef.current && tableRef.current.onQueryChange();
    }


    return (
        <>
            <main className={classes.content}>
                <Snackbar open={SnackBarState.open} autoHideDuration={6000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={handleClose}>
                    <Alert severity={SnackBarState.status} onClose={handleClose}>
                        {SnackBarState.message}
                    </Alert>
                </Snackbar>
                <AlertDialog
                    fullWidth={true}
                    open={ModalData.open}
                    okButtonHandle={handleSubmit(getBill)}
                    okButton="Сформировать"
                    cancelButton="Отмена"
                    cancelButtonHandle={() => {
                        setModalData({...ModalData, open: false})
                    }}
                    dialogContent={
                        <form className={classes.form} noValidate>
                            <DefaultInput
                                rules={{
                                    required: "Это поле обязательно",
                                    pattern: {
                                        value: /\d+$/,
                                        message: "Только цифры"
                                    },
                                }}
                                control={control}
                                helperText={errors.amount?.message}
                                error={!!errors.amount}
                                label="Сумма платежа"
                                name="amount"
                                value=""
                            />
                        </form>
                    }
                    dialogTitle={ModalData.title}
                />

                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    <MyTable
                        tableRef={tableRef}
                        options={{
                            search: false
                        }}
                        title="Платежи"
                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <div style={{padding: '0px 10px', 'text-align': 'right'}}>
                                        <Tooltip title="Добавить">
                                            <IconButton color="inherit">
                                                <AddBox onClick={() => {
                                                    setModalData({...ModalData, open: true})
                                                }}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            ),
                        }}
                        data={query =>
                            new Promise(async (resolve, reject) => {
                                const response = await send('/api/v1/user/bills/?offset=' + (query.pageSize * query.page) + '&limit=' + query.pageSize + '&contract=' + props.user.selectedContract + '&ordering=' + (query.orderDirection ? MapOrdering[query.orderDirection] : '') + (query.orderBy ? query.orderBy.field : ''));
                                if (response.status !== 200) {
                                    reject();
                                }
                                const data = await response.json();
                                if (Object.keys(reportLoadId.current).length !== 0) {
                                    for (let i in data.results) {
                                        if (reportLoadId.current[data.results[i].id]) {
                                            if (data.results[i].status !== 0) {
                                                setSnackBarState({
                                                    ...SnackBarState,
                                                    open: true,
                                                    message: 'Счет сформирован',
                                                    status: 'success'
                                                });
                                                delete reportLoadId.current[data.results[i].id];
                                            } else {
                                                if (!timerIdMap.current[data.results[i].id]) {
                                                    timerIdMap.current[data.results[i].id] = setInterval(async function () {
                                                        const rowId = data.results[i].id;
                                                        const response = await send('/api/v1/user/bills/' + rowId + '/');
                                                        const result = await response.json();
                                                        if (result.status !== 0) {
                                                            setSnackBarState({
                                                                ...SnackBarState,
                                                                open: true,
                                                                message: 'Счет сформирован',
                                                                status: 'success'
                                                            });
                                                            clearInterval(timerIdMap.current[rowId]);
                                                            delete timerIdMap.current[rowId];
                                                            delete reportLoadId.current[rowId];
                                                            tableRef.current && tableRef.current.onQueryChange();
                                                        }

                                                    }, 3000);
                                                }
                                            }
                                            break;
                                        }
                                    }
                                }
                                resolve({
                                    data: data.results,
                                    page: query.page,
                                    totalCount: data.count,
                                });
                            })
                        }
                        columns={[
                            {
                                title: 'Скачать',
                                field: 'file',
                                sorting: false,
                                render: rowData => rowData.file && <a rel="noopener noreferrer" target="_blank" className={classes.download} href={rowData.file}>Загрузить</a>
                            },
                            {
                                title: 'Дата формирования',
                                field: 'created_date',
                                render: rowData => {
                                    return new Date(rowData.created_date).toLocaleString()
                                }
                            },
                            {
                                title: 'Дата оплаты',
                                field: 'paid_date',
                                render: rowData => {
                                    return rowData.paid_date ? new Date(rowData.paid_date).toLocaleString() : 'Не оплачен'
                                }
                            },
                            {title: 'Статус', field: 'display_status'},
                            {title: 'Сумма', field: 'amount'}
                        ]}

                        actions={[]}
                    />
                </Container>
            </main>
        </>
    );
}
