import Container from "@material-ui/core/Container";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Controller, useForm} from "react-hook-form";
import {DefaultInput} from "../Utils/Forms";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import { AddBox } from "@material-ui/icons";
import "moment/locale/ru";
import {send} from "../Utils/Requests";
import Snackbar from "@material-ui/core/Snackbar";
import { MyTable } from "../Helpers/Table";
import {AlertDialog} from "../Utils/Modals";
import {validLogin} from "../Utils/Validators";
import {MTableToolbar} from "material-table";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Alert} from "../Helpers/Alerts";
import Switch from "@material-ui/core/Switch";
import {LimitByFuels} from "./LimitByFuels";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import Slide from "@material-ui/core/Slide";
import {UserAuto} from "./UserAutos";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    colorBar: {
        'background-color': '#343a40'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function Drivers(props) {
    const classes = useStyles();
    const [ModalData, setModalData] = useState({
        open: false,
        title: 'Добавить'
    });
    const [ShowUserAuto, setShowUserAuto] = useState(false);
    const [DeleteModalData, setDeleteModalData] = useState({open: false});
    const [LimitModalData, setLimitModalData] = useState({open: false, title: 'Лимит'});
    const [SubUser, setSubUser] = useState({});
    const UserLimitInitState = {
        transfer_balance: false,
        active: true
    };
    const [SubUserLimit, setSubUserLimit] = useState(UserLimitInitState);
    const [SnackBarState, setSnackBarState] = useState({
        open: false,
        status: 'success',
        message: ''
    });

    const tableRef = useRef();
    const {handleSubmit, control, errors} = useForm({mode: 'onChange'});
    const [open, setOpen] = React.useState(false);

    const handleClickOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(function () {
        tableRef.current && tableRef.current.onQueryChange();
    }, [props.user]);

    function cancelDialog(e) {
        setModalData({...ModalData, open: false});
    }

    function createDriver(e) {
        setSubUser({});
        setModalData({...ModalData, open: true, title: 'Добавить'});
    }

    async function okDialog(data) {
        const user_id_url = SubUser.id ? SubUser.id + '/' : '';
        const response = await send('/api/v1/user/users/' + user_id_url, data, SubUser.id ? 'PATCH' : 'POST');
        if (response.status >= 400) {
            setSnackBarState({open: true, status: 'error', message: 'Ошибка сохранения'});
            return
        }
        setSnackBarState({open: true, status: 'success', message: 'Сохранено'});
        setModalData({...ModalData, open: false});
        // const response_data = await response.json();

        tableRef.current && tableRef.current.onQueryChange();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({...SnackBarState, open: false});
    };

    return (
        <>
            <main className={classes.content}>
                <Snackbar open={SnackBarState.open} autoHideDuration={6000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={handleClose}>
                    <Alert severity={SnackBarState.status} onClose={handleClose}>
                        {SnackBarState.message}
                    </Alert>
                </Snackbar>
                <Dialog fullScreen open={open} onClose={handleCloseModal} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.colorBar}>
                            <IconButton edge="start" color="inherit" onClick={handleCloseModal} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {SubUser.last_name} {SubUser.first_name} ({SubUser.display_login})
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {ShowUserAuto ? <UserAuto SubUser={SubUser}
                                              user={props.user}
                                              setSnackBarState={setSnackBarState}
                                              setSubUserLimit={setSubUserLimit}
                                              SubUserLimit={SubUserLimit}
                                              setSubUser={setSubUser}
                                              UserLimitInitState={UserLimitInitState}/> : <LimitByFuels
                        SubUser={SubUser}
                        user={props.user}
                        setSnackBarState={setSnackBarState}
                        setSubUserLimit={setSubUserLimit}
                        SubUserLimit={SubUserLimit}
                        setSubUser={setSubUser}
                        UserLimitInitState={UserLimitInitState}
                    />}
                </Dialog>

                <AlertDialog
                    fullWidth={true}
                    open={ModalData.open}
                    okButtonHandle={handleSubmit(okDialog)}
                    okButton="Сохранить"
                    cancelButton="Отмена"
                    cancelButtonHandle={cancelDialog}
                    dialogContent={
                        <form className={classes.form} noValidate>
                            {!SubUser.id && <DefaultInput
                                rules={{
                                    validate: validLogin,
                                    required: "Это поле обязательно"
                                }}
                                control={control}
                                helperText={errors.login?.message}
                                error={!!errors.login}
                                label="Телефон или email"
                                name="login"
                                value={SubUser.login}
                            />}
                            <DefaultInput
                                rules={{
                                    required: "Это поле обязательно"
                                }}
                                control={control}
                                helperText={errors.last_name?.message}
                                error={!!errors.last_name}
                                label="Фамилия"
                                name="last_name"
                                value={SubUser.last_name}
                            />
                            <DefaultInput
                                rules={{
                                    required: "Это поле обязательно"
                                }}
                                control={control}
                                helperText={errors.first_name?.message}
                                error={!!errors.first_name}
                                label="Имя"
                                name="first_name"
                                value={SubUser.first_name}
                            />
                        </form>
                    }
                    dialogTitle={ModalData.title}
                />
                <AlertDialog
                    fullWidth={true}
                    open={DeleteModalData.open}
                    okButtonHandle={handleSubmit(async (data) => {
                        const response = await send('/api/v1/user/users/' + SubUser.id + '/', data, 'DELETE');
                        if(response.status >= 400){
                            setSnackBarState({open: true, status: 'error', message: 'Ошибка удаления'});
                            return
                        }
                        setSnackBarState({open: true, status: 'success', message: 'Удалено'});
                        setSubUser({});
                        setDeleteModalData({...DeleteModalData, open: false});
                        tableRef.current && tableRef.current.onQueryChange();
                    })}
                    okButton="Удалить"
                    cancelButton="Отмена"
                    cancelButtonHandle={(e) => {setDeleteModalData({...DeleteModalData, open: false})}}
                    dialogContentText="Все связанные с ним данные будут удалены"
                    dialogTitle="Вы хотите удалить пользователя?"
                />
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    {/*<Grid item xs={12}>*/}
                        <MyTable
                            tableRef={tableRef}
                            title="Водители"
                            // other props
                            options={{
                                search: false
                            }}
                            data={query =>
                                new Promise(async (resolve, reject) => {
                                    const response = await send('/api/v1/user/users/?contract=' + props.user.selectedContract + '&offset=' + (query.pageSize * query.page) + '&limit=' + query.pageSize);
                                    if (response.status !== 200) {
                                        reject();
                                    }

                                    const data = await response.json();

                                    let permitted = {};
                                    for(let i in data.results){
                                        permitted[data.results[i].id] = data.results[i].permitted;
                                    }
                                    // setPermittedState({...PermittedState, ...permitted});

                                    resolve({
                                        data: data.results,
                                        page: query.page,
                                        totalCount: data.count,
                                    });
                                })
                            }
                            columns={[
                                {title: 'Логин', field: 'display_login'},
                                {title: 'Имя', field: 'first_name'},
                                {title: 'Фамилия', field: 'last_name'},
                                {
                                    title: 'Разрешить покупку',
                                    sorting: false,
                                    field: 'permitted',
                                    render: rowData => (<Switch
                                        // checked={PermittedState[rowData.id]}
                                        checked={rowData.permitted}
                                        onChange={async (e) => {
                                            const response = await send(
                                                '/api/v1/user/users/' + rowData.id + '/change_permitted_user/',
                                                {
                                                    contract: props.user.selectedContract,
                                                    permitted: e.target.checked
                                                },
                                                'POST'
                                            );
                                            const response_data = await response.json();
                                            if (response.status !== 200) {
                                                // setPermittedState({...PermittedState});
                                            }
                                            else {
                                                tableRef.current.onQueryChange();
                                                // setPermittedState({...PermittedState, [rowData.id]: response_data.permitted});
                                            }

                                        }}
                                    />)
                                }
                            ]}
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <MTableToolbar {...props} />
                                        <div style={{padding: '0px 10px', 'text-align': 'right'}}>
                                            <Tooltip title="Добавить">
                                                <IconButton color="inherit">
                                                    <AddBox onClick={createDriver}/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                            }}
                            actions={[
                                {
                                    icon: DriveEtaIcon,
                                    tooltip: 'Автомобили',
                                    onClick: (event, rowData) => {
                                        setSubUser(rowData);
                                        setShowUserAuto(true);
                                        console.log(rowData);
                                        setOpen(true);
                                    }
                                },
                                {
                                    icon: props.user.contracts.find(({id}) => id === props.user.selectedContract).contract_type_entity === 2 ? LocalCarWashIcon: LocalGasStationIcon,
                                    // hidden: props.user.contracts.find(({id}) => id === props.user.selectedContract).contract_type_entity === 2,
                                    tooltip: 'Настройки лимитов',
                                    onClick: (event, rowData) => {
                                        setSubUser(rowData);
                                        setShowUserAuto(false);
                                        setOpen(true);
                                        const current_limit = rowData.limits.find((element, index, array) => {
                                            return element.contract === props.user.selectedContract;
                                        });
                                        if(!current_limit){
                                            setSubUserLimit(UserLimitInitState)
                                        }
                                        else{
                                            setSubUserLimit({...SubUserLimit, ...current_limit});
                                        }
                                        setLimitModalData({...LimitModalData, open: true, title: 'Редактировать'})
                                    }
                                },
                                {
                                    icon: EditIcon,
                                    tooltip: 'Редактировать',
                                    onClick: (event, rowData) => {
                                        setSubUser(rowData);
                                        console.log(rowData);
                                        setModalData({...ModalData, open: true, title: 'Редактировать'})
                                    }
                                },
                                {
                                    icon: DeleteIcon,
                                    tooltip: 'Удалить',
                                    onClick: (event, rowData) => {
                                        setSubUser(rowData);
                                        setDeleteModalData({...setDeleteModalData, open: true})
                                    }
                                }
                            ]}
                        />
                    {/*</Grid>*/}

                </Container>
            </main>
        </>
    );
}
