import Container from "@material-ui/core/Container";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Map, TileLayer, Marker, Popup, Tooltip, FeatureGroup} from "react-leaflet"
import L from "leaflet"
import "moment/locale/ru";
import {send} from "../Utils/Requests";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import CloseIcon from '@material-ui/icons/Close';
import Picker from 'react-mobile-picker-scroll';
import {AddBox} from "@material-ui/icons";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    root: {
        height: '20vh',
    },
    stationNameHeader: {
        paddingTop: '10px',
        position: 'absolute'
    },
    columnNameHeader: {
        paddingTop: '10px',
        left: '50px',
        position: 'absolute'
    },
    paperSwipeable: {
        textAlign: 'center',
    },
    paperSwipeableView: {
        padding: '0 15px 15px 15px',
    },
    headerSwipeable: {
        height: '50px',
        position: 'relative'
    },
    closeButtonSwipeable: {
        right: '-12px',
        position: 'absolute'
    },
    // chooseFuelButton: {
    //     'background-color': '#9afd8e'
    // },
    backButtonSwipeable: {
        position: 'absolute',
        left: '-11px'
    },
    backButtonValueSwipeable: {
        position: 'absolute',
        left: '6px'
    },
    DrawerHeight: {
        height: '150px'
    },
    buttonFuel: {
        width: '100px',
        marginRight: '15px',
        // height: '70px',
        marginTop: '15px',
        marginBottom: '10px',
    },
    chooseButtonFuel: {
        marginTop: '15px',
        marginRight: '15px',
        width: '100px',
        marginBottom: '10px',
        // heigth: '70px',
        'background-color': '#9afd8e !important'
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    map: {
        width: '200px',
        height: 240,
    },
    download: {
        margin: '0 10px'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    containerSwipeable: {
        'white-space': 'nowrap',
        'overflow-x': 'scroll',
        marginBottom: '0px'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

function getFuelValues() {
    let results = [];
    for (let i = 2; i <= 100; i++) {
        results.push(i * 50);
    }
    return results;
}

export const afsIcon = new L.Icon({
    iconUrl: '../132х132.png',
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
});


function StationComponent(props) {
    const classes = useStyles();

    const SwipeableRef = useRef();

    function chooseColumnHandler(column) {
        props.setStationData({
            ...props.StationData,
            chooseColumn: column,
            height: SwipeableRef.current ? SwipeableRef.current.clientHeight : 0
        })
    }

    function chooseFuelHandler(fuel) {
        props.setStationData({
            ...props.StationData,
            chooseFuel: fuel,
            height: SwipeableRef.current ? SwipeableRef.current.clientHeight : 0
        })
    }

    function getChooseFuel(nozzleId) {
        for (let i in props.StationData.station_data[props.StationData.chooseColumn]) {
            if (props.StationData.station_data[props.StationData.chooseColumn][i].NozzleId === nozzleId) {
                return props.StationData.station_data[props.StationData.chooseColumn][i];
            }
        }
        return {};
    }

    if (!props.StationData.station_data) {
        return null;
    }


    return (
        <div className={classes.paperSwipeableView} ref={SwipeableRef}>
            <div className={classes.headerSwipeable}>
                {props.StationData.chooseColumn ? <><IconButton
                        className={classes.backButtonSwipeable}
                        onClick={(e) => {
                            props.setStationData({...props.StationData, chooseColumn: null, chooseFuel: null})
                        }}><ArrowBackIosIcon/></IconButton><Typography
                        fontWeight={600}
                        variant="h6"
                        className={classes.columnNameHeader}>
                        Выбрана {props.StationData.chooseColumn} колонка
                    </Typography></> :
                    <>
                        <Typography
                            fontWeight={600}
                            variant="h6"
                            className={classes.stationNameHeader}>
                            {props.StationData?.name}
                        </Typography></>}
                <IconButton
                    className={classes.closeButtonSwipeable}
                    onClick={(e) => {
                        props.setStationData({...props.StationData, open: false})
                    }}><CloseIcon/></IconButton>
            </div>
            {props.StationData.chooseColumn ? <Typography variant="subtitle1">Выберите топливо</Typography> :
                <Typography variant="subtitle1">Выберите колонку</Typography>}
            <Grid spacing={0} className={classes.containerSwipeable}>
                {!props.StationData.chooseColumn && Object.keys(props.StationData.station_data).map((column) =>
                    <IconButton
                        color="inherit" onClick={() => {
                        chooseColumnHandler(column)
                    }}><LocalGasStationIcon/>{column}</IconButton>)}
                {props.StationData.chooseColumn && props.StationData.station_data[props.StationData.chooseColumn].map((fuel) =>
                    <Button
                        variant="contained" color="inherit"
                        className={fuel.NozzleId === props.StationData.chooseFuel ? classes.chooseButtonFuel : classes.buttonFuel}
                        onClick={() => {
                            chooseFuelHandler(fuel.NozzleId)
                        }}>{fuel.Name} <br/> {fuel.Price} ₽</Button>)}
            </Grid>
            {props.StationData.chooseFuel && <>
                <div style={{position: 'relative', height: '70px'}}>
                    <Button
                        style={{'text-transform': 'capitalize', display: 'block', position: 'absolute', right: '10px'}}
                        onClick={(e) => {
                            props.setStationData({
                                ...props.StationData,
                                openValue: true,
                                height: SwipeableRef.current ? SwipeableRef.current.clientHeight : 0
                            });
                        }}
                    >
                        <Typography variant="body2" style={{'font-size': '15px'}}>Количество <Typography
                            style={{position: 'relative'}}
                            variant="h6">на {props.valueFuel.value} ₽ <NavigateNextIcon style={{
                            top: '3px',
                            position: 'absolute',
                            right: '-23px'
                        }}/></Typography></Typography>

                    </Button>
                </div>
                <Button
                    onClick={async () => {
                        const response = await send('/api/v1/user/stations/' + props.StationData.id + '/purchase/', {
                            amount: props.valueFuel.value,
                            column_id: props.StationData.chooseColumn,
                            nozzle_id: props.StationData.chooseFuel
                        }, 'POST');
                        if(response.status !== 200){

                        }
                        props.updateUser();
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth>Заправиться {((Math.floor((props.valueFuel.value / getChooseFuel(props.StationData.chooseFuel).Price) * 100) / 100) * getChooseFuel(props.StationData.chooseFuel).Price).toFixed(2)} р</Button>
            </>}
        </div>
    )
}

function MemoryMarker(props) {
    const memoryParams = props.memoryParametr;

    return <Marker {...props} onClick={(e) => {
        props.handleClick(memoryParams)
    }}>{props.children}</Marker>
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function MapComponent(props) {
    const classes = useStyles();
    const [Stations, setStations] = useState([]);
    const [MapBounds, setMapBounds] = useState([0, 0]);
    const [StationModal, setStationModal] = useState({open: false});
    const [OptionGroups, setOptionGroups] = useState({
        value: getFuelValues()
    });
    const [ValueGroup, setValueGroup] = useState({
        value: 1000
    });
    const [Column, setColumn] = useState(null);
    const MapRef = useRef();
    const LayerRef = useRef();


    useEffect(() => {
        async function getStations() {
            const response = await send('/api/v1/user/stations/?contract=' + props.user.selectedContract);
            const results = await response.json();
            for (let i in results.results) {
                if (results.results[i].icon) {
                    results.results[i].iconAfs = new L.Icon({
                        iconUrl: results.results[i].icon,
                        iconAnchor: [5, 55],
                        popupAnchor: [10, -44],
                        iconSize: [45, 45],
                        // shadowSize: [68, 95],
                        // shadowAnchor: [20, 92],
                    });
                }
            }
            setStations(results.results);
        }

        getStations();
        return () => {
        }
    }, [props.user]);
    useEffect(() => {
        LayerRef.current && MapRef.current.leafletElement.fitBounds(LayerRef.current.getBounds());
    }, [Stations]);


    return (
        <>
            <main className={classes.content}>

                <SwipeableDrawer
                    anchor="bottom"
                    disableBackdropTransition={true}
                    disableSwipeToOpen={true}
                    disableDiscovery={true}
                    open={StationModal.open}
                    onClose={(e) => {
                        setStationModal({open: false})
                    }}
                    onOpen={(e) => {
                        setStationModal({open: true})
                    }}
                >
                    <StationComponent StationData={StationModal} setStationData={setStationModal}
                                      valueFuel={ValueGroup} updateUser={props.updateUser}/>
                </SwipeableDrawer>
                <Drawer

                    anchor="bottom"
                    SlideProps={{direction: 'left'}}
                    open={StationModal.openValue}
                    // onOpen={(e) => {debugger}}
                >
                    <div style={{height: StationModal.height}}>
                        <div className={classes.headerSwipeable}><IconButton
                            className={classes.backButtonValueSwipeable}
                            onClick={(e) => {
                                setStationModal({...StationModal, openValue: false})
                            }}><ArrowBackIosIcon/></IconButton><Typography
                            fontWeight={600}
                            variant="h6"
                            className={classes.columnNameHeader}>
                            Количество
                        </Typography></div>
                        <Picker
                            valueGroups={ValueGroup}
                            onChange={(name, value) => {
                                setValueGroup({value: value})
                            }}
                            optionGroups={OptionGroups}/>
                    </div>

                </Drawer>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Map center={MapBounds} zoom={13} ref={MapRef}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <FeatureGroup onAdd={(e) => {
                                    LayerRef.current = e.target;
                                }}>
                                    {Stations.map((station) => {
                                            const iconProps = {};
                                            if (station.iconAfs) {
                                                iconProps.icon = station.iconAfs;
                                            }
                                            // const fuels = station.station_data[1];
                                            return <MemoryMarker
                                                position={station.location}
                                                key={station.id}
                                                handleClick={(params) => {
                                                    // setStationModal({
                                                    //     open: true,
                                                    //     chooseColumn: null, ...params
                                                    // })
                                                }}
                                                memoryParametr={station}
                                                {...iconProps}
                                            >
                                                {/*<Tooltip sticky>{station.address}</Tooltip>*/}
                                                {/*<Popup>*/}
                                                {/*    {fuels.map((fuel) => <p>{fuel.Name} - {fuel.Price}</p>)}*/}
                                                {/*    /!*<p></p>*!/*/}
                                                {/*    <MemoryButton type="button"*/}
                                                {/*                  handleClick={(params) => {*/}
                                                {/*                      setStationModal({*/}
                                                {/*                          open: true,*/}
                                                {/*                          chooseColumn: null, ...params*/}
                                                {/*                      })*/}
                                                {/*                  }}*/}
                                                {/*                  memoryParametr={station}*/}
                                                {/*                  fullWidth*/}
                                                {/*                  variant="contained"*/}
                                                {/*                  color="primary">Заправиться</MemoryButton>*/}
                                                {/*</Popup>*/}
                                            </MemoryMarker>
                                        }
                                    )}
                                </FeatureGroup>

                            </Map>
                        </Paper>
                    </Grid>
                </Container>
            </main>
        </>
    );
}
